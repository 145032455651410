import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This email and any attachments to it may be confidential and are intended solely for the use of the individual to whom it is addressed. Any views or opinions expressed are solely those of the author and do not necessarily represent those of Jacobs Enforcement. If you are not the intended recipient of this email, you must neither take any action based upon its contents, nor copy or show it to anyone. Please contact the sender if you believe you have received this email in error. Internet e-mail is not a 100% secure communication medium and Jacobs do not accept responsibility for changes made to this message after it was sent. Whilst all reasonable care has been taken to ensure that this message is virus-free, it is the recipient's responsibility to carry out virus checks as appropriate and ensure that the onward transmission, opening or use of this message and any attachments will not adversely affect their systems or data.`}</p>
    <p>{`Please see our Jacobs Privacy Notice for Customers
`}<a parentName="p" {...{
        "href": "/privacy-notice"
      }}>{`Jacobs Privacy Notice for Customers`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      